import { Dictionary } from '@ngrx/entity';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';

import { PlanType, SubscriptionModel } from '../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { hasAtleastSubscriptionPlan } from '../../../shared/subscription-plan/subscription-plan.directive';
import { getAccountSubscription } from '../../account/account.service';
import { getPermissionState, hasPermission } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { convertArrayToObject } from '../../shared/entity.helper';
import { IntegrationModel } from '../integration/integration.model';
import { getActiveIntegrations } from '../integration/integration.service';
import { Permission, PermissionType } from '../permission/permission.model';
import {
  IntegrationAppCategory,
  IntegrationAppModel,
  IntegrationAppTitle,
  IntegrationAppWithIntegrationsModel,
} from './integration-app.model';
import { integrationAppAdapter, IntegrationAppState } from './integration-app.state';

export const getIntegrationAppState = (appState: AppState): IntegrationAppState => appState.orm.integrationApps;

export const { selectAll, selectEntities, selectIds, selectTotal } =
  integrationAppAdapter.getSelectors(getIntegrationAppState);

export const getIntegrationApps = createSelector(
  selectAll,
  getAccountSubscription,
  getPermissionState,
  (integrationApps: IntegrationAppModel[], subscription: SubscriptionModel, permissionState): IntegrationAppModel[] => {
    if (!subscription || !permissionState) {
      return [];
    }
    const sortedApps = sortBy(integrationApps, [(integrationApp: IntegrationAppModel) => integrationApp.display_name]);

    const apiApp: IntegrationAppModel = {
      title: IntegrationAppTitle.API,
      display_name: 'API',
      price: 0,
      plan: PlanType.BASIC,
      category: IntegrationAppCategory.API,
      tooltipsToTranslate: [],
      description: 'Create a connection with your own apps.',
      url: 'app-tokens',
      integration_plus: true,
    };

    return [apiApp, ...sortedApps].map((app) => ({
      ...app,
      canEdit: hasPermission(
        {
          permissions: requiredPermissionsForCategory(app.category),
          userId: 'me',
          departments: 'any',
        },
        permissionState,
      ),
      enabled: hasAtleastSubscriptionPlan(app.plan, subscription),
      logoSrc: `assets/images/integrations/logo-${app.title.toLowerCase()}.svg${app.logoVersion ? `?v=${app.logoVersion}` : ''}`,
    }));
  },
);

export const getIntegrationAppEntities = createSelector(
  getIntegrationApps,
  getAccountSubscription,
  (integrationApps: IntegrationAppModel[]) => convertArrayToObject('title', integrationApps),
);

export const getIntegrationAppByTitle = (appTitle: IntegrationAppTitle) =>
  createSelector(
    getIntegrationAppEntities,
    getAccountSubscription,
    (integrationApps: Dictionary<IntegrationAppModel>) => integrationApps[appTitle],
  );

export const getIntegrationsByCategory = (category: IntegrationAppCategory[]) =>
  createSelector(
    getIntegrationApps,
    getActiveIntegrations,
    (integrationApps: IntegrationAppModel[], integrations): IntegrationAppWithIntegrationsModel[] =>
      integrationApps
        .filter((app) => category.includes(app.category))
        .map((app) => ({
          ...app,
          integrations: integrations.filter((integration: IntegrationModel) => integration.ApiApp?.title === app.title),
        }))
        .filter((app) => app.integrations.length > 0),
  );

const requiredPermissionsForCategory = (category: IntegrationAppCategory): Permission[] => {
  switch (category) {
    case IntegrationAppCategory.PAYROLL:
    case IntegrationAppCategory.HR:
      return [PermissionType.MANAGE_PAYROLL_APPS];
    case IntegrationAppCategory.API:
      return [PermissionType.MANAGE_API_TOKENS];
    default:
      return [PermissionType.MANAGE_NON_PAYROLL_APPS];
  }
};
