import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IntegrationAppModel } from './integration-app.model';

export interface IntegrationAppState extends EntityState<IntegrationAppModel> {
  isLoading?: boolean;
  error?: any;
}

export const integrationAppAdapter: EntityAdapter<IntegrationAppModel> = createEntityAdapter<IntegrationAppModel>({
  selectId: (app) => app.title,
});

export const initialState: IntegrationAppState = integrationAppAdapter.getInitialState({
  isLoading: false,
  error: null,
});
